import React, { useState, forwardRef } from "react";
import {
  Typography,
  Box,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Input,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  AccountCircle,
  Email,
  Phone,
  CalendarToday,
  Receipt,
  LocalMall,
} from "@mui/icons-material";

const DetailedFeedbackForm = forwardRef(
  ({ feedback, onFeedbackChange, rating, locationData }, ref) => {
    const [orderPlatform, setOrderPlatform] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [date, setDate] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [feedbackDescription, setFeedBackDescription] =
      useState("test_feedback"); // podio needs at least one character
    const [additionalFeedback, setAdditionalFeedback] = useState(""); // New state for additional feedback
    const [consent, setConsent] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const navigate = useNavigate();

    const validateFeedback = () => {
      let newErrors = {};
      console.log("Validating: ", {
        email,
        orderNumber,
        name,
        phoneNumber,
        date,
        rating,
      });

      if (!email.includes("@") || !email.includes(".")) {
        newErrors.email = "Please enter a valid email address.";
        console.log("Email error");
      }
      if (!orderNumber.trim()) {
        newErrors.orderNumber = "Order number is required.";
        console.log("Order Number error");
      }
      if (!name.trim()) {
        newErrors.name = "Your name is required.";
        console.log("Name error");
      }
      if (!phoneNumber.match(/^\d{10}$/)) {
        newErrors.phoneNumber = "Please enter a valid 10-digit phone number.";
        console.log("Phone Number error");
      }
      if (!date) {
        newErrors.date = "Please select a date.";
        console.log("Date error");
      }
      if (rating === 0) {
        newErrors.rating = "Please select a rating from 1 to 5.";
        console.log("Rating error");
      }
      console.log("Errors: ", newErrors);
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const sendFeedbackData = async (feedbackData) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL_HOST}/podio/add-feedback`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(feedbackData),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        console.log("Feedback submitted successfully:", responseData);
        return responseData;
      } catch (error) {
        console.error("Failed to submit feedback:", error);
      }
    };

    const handleSubmit = async (event) => {
      console.log("button clicked");
      event.preventDefault();
      setIsLoading(true);

      if (!validateFeedback()) {
        console.log("false");
        setIsLoading(false);
        return;
      }

      const feedbackData = {
        order_platform: orderPlatform,
        order_number: orderNumber,
        order_time: date,
        name: name,
        email: email,
        phone_number: phoneNumber,
        consent: consent,
        rating: rating,
        additional_feedback: additionalFeedback,
        location_id: locationData.location_id,
      };

      try {
        console.log(feedbackData);

        // insert feedback data into db and update podio
        const response = sendFeedbackData(feedbackData);
        // console.log(response);

        navigate("/thank-you", {
          state: { rating: rating, locationData: locationData },
        });
      } catch (error) {
        console.error("Error submitting feedback:", error);
        alert("Failed to submit feedback!"); // Optionally alert the user
      } finally {
        setIsLoading(false);
      }
    };

    const handleImageChange = (event) => {
      setSelectedImage(event.target.files[0]);
    };

    return (
      <Box ref={ref} className="form-container">
        <Typography variant="h6" className="subheader" sx={{ mb: 2 }}>
          We value your feedback and would love to hear about your experience.
          Please fill out the details below:
        </Typography>
        <form onSubmit={handleSubmit} noValidate className="feedback-form">
          <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Order Platform</InputLabel>
            <Select
              value={orderPlatform}
              onChange={(e) => setOrderPlatform(e.target.value)}
              label="Order Platform"
              startAdornment={<LocalMall className="icon" />}
            >
              <MenuItem value="website">Website</MenuItem>
              <MenuItem value="app">App</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="in-store">In-store</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Order Number"
            variant="outlined"
            fullWidth
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
            error={!!errors.orderNumber}
            helperText={errors.orderNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Receipt className="icon" />
                </InputAdornment>
              ),
            }}
            className="icon-text-field"
            sx={{ mb: 2 }}
          />
          <TextField
            label="Date of Visit"
            type="date"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            error={!!errors.date}
            helperText={errors.date}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarToday className="icon" />
                </InputAdornment>
              ),
            }}
            className="icon-text-field"
            sx={{ mb: 2 }}
          />
          <TextField
            label="Your Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle className="icon" />
                </InputAdornment>
              ),
            }}
            className="icon-text-field"
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email className="icon" />
                </InputAdornment>
              ),
            }}
            className="icon-text-field"
            sx={{ mb: 2 }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone className="icon" />
                </InputAdornment>
              ),
            }}
            className="icon-text-field"
            sx={{ mb: 2 }}
          />
          <TextField
            label="Additional Feedback"
            variant="outlined"
            fullWidth
            value={additionalFeedback}
            onChange={(e) => setAdditionalFeedback(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Input
            type="file"
            onChange={handleImageChange}
            sx={{ mb: 2 }}
            inputProps={{ accept: "image/*" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={consent}
                onChange={(e) => setConsent(e.target.checked)}
              />
            }
            label="I agree to be contacted regarding my feedback."
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
            className="button-primary"
            sx={{ mt: 2 }}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit Feedback"}
          </Button>
        </form>
      </Box>
    );
  }
);

export default DetailedFeedbackForm;
