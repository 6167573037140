import React from "react";
import { Typography, Box, Rating, CardMedia } from "@mui/material";
import { Star, StarBorder, StarHalf } from "@mui/icons-material";
import logo from "../imgs/MOLTN_Primary_Logotype_cropped@5x.png";

function IntroductionScreen({ rating, onRatingChange, locationData }) {
  return (
    <Box textAlign="center" p={2} className="container">
      <CardMedia
        component="img"
        image={logo}
        alt="MOLTN Cookies Logo"
        sx={{ width: 120, height: "auto", mx: "auto", mt: 2 }}
      />
      <Box className="form-container">
        <Typography variant="h4" className="header">
          We value your feedback!
        </Typography>
        <Typography variant="subtitle1" className="subheader">
          We're genuinely grateful for your visit to{" "}
          {locationData.location_name} and invite you to share your experience.
          Your feedback helps us grow and ensures your next MOLTN moment is even
          more delightful.
        </Typography>
        <Box className="rating-container">
          <Typography variant="h6" className="subheader" sx={{ mb: 2 }}>
            How would you rate your satisfaction with our cookies and service at{" "}
            {locationData.location_name}?
          </Typography>
          <Rating
            name="feedback-rating"
            size="large"
            value={rating}
            onChange={(event, newValue) => {
              onRatingChange(newValue);
            }}
            icon={<Star fontSize="inherit" htmlColor="#63C0C3" />}
            emptyIcon={<StarBorder fontSize="inherit" htmlColor="#63C0C3" />}
            halfIcon={<StarHalf fontSize="inherit" htmlColor="#63C0C3" />}
            sx={{ fontSize: "40px" }} // Increased size for better mobile experience
          />
        </Box>
      </Box>
    </Box>
  );
}

export default IntroductionScreen;
