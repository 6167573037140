import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../supabase";

function LocationWrapper({ children, onLocationDataChange }) {
  const { locationId } = useParams();
  const [locationData, setLocationData] = useState(null);

  useEffect(() => {
    async function fetchLocationData() {
      const { data, error } = await supabase
        .from("Location")
        .select()
        .eq("location_id", locationId)
        .single();

      if (error) {
        console.error("Error fetching location data:", error);
      } else {
        setLocationData(data);
        onLocationDataChange(data); // Pass data to parent component
      }
    }

    fetchLocationData();
  }, [locationId]);

  if (!locationData) {
    return <div>Loading...</div>; // Simple loading state
  }

  // React.cloneElement allows for passing extra props to children
  return React.Children.map(children, (child) =>
    React.isValidElement(child)
      ? React.cloneElement(child, { locationData })
      : child
  );
}

export default LocationWrapper;
