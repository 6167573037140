import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#63C0C3', // MOLTN Blue
    },
    secondary: {
      main: '#66300F', // MOLTN Choc
    },
    background: {
      default: '#FAF9F7', // MOLTN Cream
    },
    text: {
      primary: '#000000', // Black
      secondary: '000000', // White
    },
  },
  typography: {
    fontFamily: 'Silka, Kaneda Gothic, sans-serif',
    h1: {
      fontFamily: 'Kaneda Gothic, sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Kaneda Gothic, sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Kaneda Gothic, sans-serif',
      fontWeight: 700,
    },
    body1: {
      fontFamily: 'Silka, sans-serif',
      fontWeight: 400,
    },
    button: {
      fontFamily: 'Silka, sans-serif',
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            padding: '12px',
          },
          '& .MuiInputLabel-root': {
            fontFamily: 'Silka, sans-serif',
            fontWeight: 400,
          },
          '& .MuiFormHelperText-root': {
            fontFamily: 'Silka, sans-serif',
            fontWeight: 400,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        select: {
          padding: '12px',
        },
        icon: {
          marginRight: '8px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#66300F',
        },
      },
    },
  },
});

export default theme;
