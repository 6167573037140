import React, { useState, useRef, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { CssBaseline, Container, Box, Button } from "@mui/material";
import IntroductionScreen from "./components/IntroductionScreen";
import DetailedFeedbackForm from "./components/FeedbackForm/DetailedFeedbackForm";
import ThankYouPage from "./components/ThankYouPage";
import LocationWrapper from "./components/LocationWrapper"; // Assuming this is correctly defined

import "./App.css";

function App() {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState({});
  const [locationData, setLocationData] = useState(null);
  const navigate = useNavigate();
  const feedbackFormRef = useRef(null);

  useEffect(() => {
    if (rating > 0 && rating <= 3 && feedbackFormRef.current) {
      const elementRect = feedbackFormRef.current.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const fixedOffset = 60;
      window.scrollTo({
        top: absoluteElementTop - fixedOffset,
        behavior: "smooth",
      });
    }
  }, [rating]);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleFeedbackChange = (newFeedback) => {
    setFeedback(newFeedback);
  };

  const handleSubmit = async () => {
    // console.log("location:" + locationData);

    // we could probblay just call the funtionc without wairing for the repsonse to speed this up
    sendFeedbackData();

    // console.log("Result of send feedback data:" + result);
    navigate("/thank-you", {
      state: { rating: rating, locationData: locationData },
    });
  };
  const handleLocationDataChange = (data) => {
    console.log("location data changed:" + data);
    setLocationData(data);
  };

  const fetchIpAddress = async () => {
    try {
      const response = await fetch("https://geolocation-db.com/json/");
      const data = await response.json();
      return data.IPv4;
    } catch (error) {
      console.error("Error fetching the IP address:", error);
      return null;
    }
  };
  const sendFeedbackData = async () => {
    // get ip addres
    const ipAddr = await fetchIpAddress();
    console.log("Got ip address:" + ipAddr);

    const feedbackData = {
      order_platform: "Undefined",
      order_number: "00000",
      order_time: "",
      name: ipAddr || "Undefined",
      email: "Undefined",
      phone_number: "Undefined",
      consent: false,
      rating: rating,
      additional_feedback: "Undefined",
      location_id: locationData.location_id,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL_HOST}/podio/add-feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(feedbackData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("Feedback submitted successfully:", responseData);
      return responseData;
    } catch (error) {
      console.error("Failed to submit feedback:", error);
    }
  };

  return (
    <Container maxWidth="sm">
      <CssBaseline />
      <Box mt={4} mb={2} textAlign="center">
        <Routes>
          <Route
            path="/location/:locationId"
            element={
              <LocationWrapper onLocationDataChange={handleLocationDataChange}>
                <IntroductionScreen
                  rating={rating}
                  onRatingChange={handleRatingChange}
                />
                {rating > 0 && rating <= 3 && (
                  <DetailedFeedbackForm
                    ref={feedbackFormRef}
                    feedback={feedback}
                    onFeedbackChange={handleFeedbackChange}
                    rating={rating}
                  />
                )}
                {rating > 3 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={rating === 0}
                    sx={{ mt: 2 }}
                  >
                    Submit Feedback
                  </Button>
                )}
              </LocationWrapper>
            }
          />
          <Route path="/thank-you" element={<ThankYouPage />} />
        </Routes>
      </Box>
    </Container>
  );
}

export default App;
