import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Stack,
  Card,
  Grow,
  CardMedia,
} from "@mui/material";
import googleIcon from "../imgs/google_g_icon_download.png";
import yelpIcon from "../imgs/yelp_favicon.svg";
import logo from "../imgs/MOLTN_Primary_Logotype_cropped@5x.png";

function ThankYouPage() {
  const location = useLocation();
  const { rating, locationData } = location.state || {};
  console.log(rating);
  console.log(locationData);
  const navigate = useNavigate();

  const messages = {
    5: {
      text: "We're thrilled to hear you loved our cookies! If you could take a moment to share your experience on Google or Yelp, we'd greatly appreciate it. Your support helps us grow and improve!",
      showReviewLinks: true,
      showLoyaltyButton: true,
    },
    4: {
      text: "Thank you for your feedback! We're always looking to improve. Sign up for our Loyalty Program now and get $5 off your next order. Enjoy more of what you love and stay updated with our latest offerings.",
      showReviewLinks: false,
      showLoyaltyButton: true,
    },
    default: {
      text: "We're sorry to hear that your experience was anything less than perfect. We take your feedback seriously and will use it to make improvements.",
      showReviewLinks: false,
      showLoyaltyButton: false,
    },
  };

  const { text, showReviewLinks, showLoyaltyButton } =
    rating === 5 ? messages[5] : rating === 4 ? messages[4] : messages.default;
  // console.log(locationData.google_business_listing);
  console.log(locationData);
  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 1000 }}>
      <Card
        variant="outlined"
        sx={{ textAlign: "center", p: 2, mt: 4 }}
        className="card"
      >
        <CardMedia
          component="img"
          image={logo}
          alt="MOLTN Cookies Logo"
          sx={{ width: 150, height: "auto", mx: "auto", my: 2 }}
        />
        <Typography variant="h5" gutterBottom className="header">
          {text}
        </Typography>
        {showReviewLinks && (
          <Stack direction="row" spacing={2} justifyContent="center" my={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(locationData.google_business_listing, "_blank")
              }
              className="button-primary"
            >
              <Box
                component="img"
                src={googleIcon}
                alt="Google"
                sx={{ height: 24 }}
              />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(locationData.yelp_profile, "_blank")}
              className="button-primary"
            >
              <Box
                component="img"
                src={yelpIcon}
                alt="Yelp"
                sx={{ height: 24 }}
              />
            </Button>
          </Stack>
        )}
        {showLoyaltyButton && (
          <Button
            variant="contained"
            color="secondary"
            // locationData.
            onClick={() => window.open(locationData.loyalty_url, "_blank")}
            sx={{ my: 2 }}
            className="button-secondary"
          >
            Join Our Loyalty Program
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => (window.location.href = "https://moltncookies.com")}
          sx={{ mt: 2 }}
          className="button-primary"
        >
          Go Back to Home
        </Button>
      </Card>
    </Grow>
  );
}

export default ThankYouPage;
